<template>
  <div class="index">
    <div class="nav1">
      <div class="tit1">模型管理</div>
    </div>
    <div class="nav2">
      <div class="myForm">
        <div class="myForm">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-row>
              <el-col :span="12"><div>
                <div>
                  <el-checkbox v-model="v1" @change="change1">工作段1</el-checkbox>
                  <el-checkbox v-model="hy1">行业1</el-checkbox>
                  <el-checkbox v-model="gs1">公司1</el-checkbox>
                  <el-checkbox v-model="zy1">专业1</el-checkbox>
                  <el-checkbox v-model="zj1">职级1</el-checkbox>
                  <el-radio-group v-model="y1">
                    <el-radio label="A">0-2</el-radio>
                    <el-radio label="B">2-3</el-radio>
                    <el-radio label="C">3-5</el-radio>
                    <el-radio label="D">5+</el-radio>
                  </el-radio-group>
                </div>
                <div>
                  <el-checkbox v-model="v2" @change="change2">工作段2</el-checkbox>
                  <el-checkbox v-model="hy2">行业2</el-checkbox>
                  <el-checkbox v-model="gs2">公司2</el-checkbox>
                  <el-checkbox v-model="zy2">专业2</el-checkbox>
                  <el-checkbox v-model="zj2">职级2</el-checkbox>
                  <el-radio-group v-model="y2">
                    <el-radio label="A">0-2</el-radio>
                    <el-radio label="B">2-3</el-radio>
                    <el-radio label="C">3-5</el-radio>
                    <el-radio label="D">5+</el-radio>
                  </el-radio-group>
                </div>
                <div>
                  <el-checkbox v-model="v3" @change="change3">工作段3</el-checkbox>
                  <el-checkbox v-model="hy3">行业3</el-checkbox>
                  <el-checkbox v-model="gs3">公司3</el-checkbox>
                  <el-checkbox v-model="zy3">专业3</el-checkbox>
                  <el-checkbox v-model="zj3">职级3</el-checkbox>
                  <el-radio-group v-model="y3">
                    <el-radio label="A">0-2</el-radio>
                    <el-radio label="B">2-3</el-radio>
                    <el-radio label="C">3-5</el-radio>
                    <el-radio label="D">5+</el-radio>
                  </el-radio-group>
                </div>
                <div>
                  <el-checkbox v-model="v4" @change="change4">工作段4</el-checkbox>
                  <el-checkbox v-model="hy4">行业4</el-checkbox>
                  <el-checkbox v-model="gs4">公司4</el-checkbox>
                  <el-checkbox v-model="zy4">专业4</el-checkbox>
                  <el-checkbox v-model="zj4">职级4</el-checkbox>
                  <el-radio-group v-model="y4">
                    <el-radio label="A">0-2</el-radio>
                    <el-radio label="B">2-3</el-radio>
                    <el-radio label="C">3-5</el-radio>
                    <el-radio label="D">5+</el-radio>
                  </el-radio-group>
                </div>
              </div></el-col>
              <el-col :span="12"><div >
                <el-input
                    type="textarea"
                    :rows="6"
                    placeholder="匹配结果"
                    v-model="vmsg">
                </el-input>
              </div></el-col>
            </el-row>



          </el-form>
          <div class="tit1">
            <el-button @click="SearchModel" size="small" type="primary" icon="el-icon">模型查询</el-button>
            <el-button @click="ModelCheck" size="small" type="primary" ><el-icon><Printer /></el-icon>模型校验</el-button>
            <el-button @click="ImportModel" size="small" type="primary" icon="el-icon-upload">导入模型</el-button>
          </div>
        </div>
      </div>

      <div class="myTable">
        <vxe-table :loading="loading"
                   align="center"
                   :data="tableData"
                   :header-cell-class-name="headerCellClassName"
                   :row-class-name="rowClassName"
                   :cell-class-name="cellClassName"
                   ref="myTable"
                   :import-config="importConfig"
        >
          <vxe-table-column field="hy1" width="50" title="行业1"></vxe-table-column>
          <vxe-table-column field="gs1" width="50" title="公司1"></vxe-table-column>
          <vxe-table-column field="zy1" width="50" title="专业1"></vxe-table-column>
          <vxe-table-column field="zj1" width="50" title="职级1"></vxe-table-column>

          <vxe-table-column field="hy2" width="50" title="行业2"></vxe-table-column>
          <vxe-table-column field="gs2" width="50" title="公司2"></vxe-table-column>
          <vxe-table-column field="zy2" width="50" title="专业2"></vxe-table-column>
          <vxe-table-column field="zj2" width="50" title="职级2"></vxe-table-column>

          <vxe-table-column field="hy3" width="50" title="行业3"></vxe-table-column>
          <vxe-table-column field="gs3" width="50" title="公司3"></vxe-table-column>
          <vxe-table-column field="zy3" width="50" title="专业3"></vxe-table-column>
          <vxe-table-column field="zj3" width="50" title="职级3"></vxe-table-column>

          <vxe-table-column field="hy4" width="50" title="行业4"></vxe-table-column>
          <vxe-table-column field="gs4" width="50" title="公司4"></vxe-table-column>
          <vxe-table-column field="zy4" width="50" title="专业4"></vxe-table-column>
          <vxe-table-column field="zj4" width="50" title="职级4"></vxe-table-column>

          <vxe-table-column field="y1" width="50" title="经验1"></vxe-table-column>
          <vxe-table-column field="y2" width="50" title="经验2"></vxe-table-column>
          <vxe-table-column field="y3" width="50" title="经验3"></vxe-table-column>
          <vxe-table-column field="y4" width="50" title="经验4"></vxe-table-column>

          <vxe-table-column field="h" width="60" title="高-高层"></vxe-table-column>
<!--          <vxe-table-column field="h1" width="100" title="高层含义"></vxe-table-column>-->
          <vxe-table-column field="m" width="60" title="高-中层"></vxe-table-column>
<!--          <vxe-table-column field="m1" width="100" title="中层含义"></vxe-table-column>-->
          <vxe-table-column field="l" width="60" title="高-基层"></vxe-table-column>
<!--          <vxe-table-column field="l1" width="100" title="基层含义"></vxe-table-column>-->
          <vxe-table-column field="h2" width="60" title="中-高层"></vxe-table-column>
          <vxe-table-column field="m2" width="60" title="中-中层"></vxe-table-column>
          <vxe-table-column field="l2" width="60" title="中-基层"></vxe-table-column>
          <vxe-table-column field="h3" width="60" title="低-高层"></vxe-table-column>
          <vxe-table-column field="m3" width="60" title="低-中层"></vxe-table-column>
          <vxe-table-column field="l3" width="60" title="低-基层"></vxe-table-column>
        </vxe-table>
        <el-pagination class="fenye" @size-change="this.handleSizeChange" @current-change="this.handleCurrentChange"
                       :current-page="this.biaobaiqiangPage" :page-size="10" :page-sizes="[10, 15, 20, 30]"
                       layout="total,sizes, prev, pager, next, jumper" :total="this.total"></el-pagination>
      </div>
    </div>
    <!-- 添加/修改广告 -->
    <el-dialog title="添加/修改广告" :visible.sync="addDialogVisible" width="700px" :before-close="addHandleClose">
      <div class="myAddForm">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" class="demo-addForm">
          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button size="small" type="primary" @click="AddOnSubmit('addForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <input type="file" name="companyLogo" id="file0" class="displayN" multiple="multiple" @change="companyLogo($event)"
           ref="fileInputList" />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState([
      "tabShopIndex",
      "biaobaiqiangPage",
      "biaobaiqiangPageSize",
      "xiaoshoubiaoPage",
      "xiaoshoubiaoPageSize"
    ])
  },
  watch: {
    tabShopIndex: function () {
      this.activeName = this.tabShopIndex;
      console.log(this.activeName);
      this.getData();
    },
    biaobaiqiangPage: function (page) {
      this.$store.commit("biaobaiqiangPage", page);
      this.getData();
    },
    biaobaiqiangPageSize: function (pageSize) {
      this.$store.commit("biaobaiqiangPageSize", pageSize);
      this.getData();
    },
    xiaoshoubiaoPage: function (page) {
      this.$store.commit("xiaoshoubiaoPage", page);
      this.getxsData();
    },
    xiaoshoubiaoPageSize: function (pageSize) {
      this.$store.commit("xiaoshoubiaoPageSize", pageSize);
      this.getxsData();
    }
  },
  data() {
    return {
      is_status: -1,
      loading: false,
      activeName: "1",
      searchForm: {
        challenges_id: "",
      },
      tableData: [],
      total: 0,
      addDialogVisible: false,
      addForm: {
        is_status: '',
        category: '',
        name:"",
        url_type:"",
        url:"",
        pic:"",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      imgStatus: "",
      imgFile: "",
      id: "",
      xsaddDialogVisible: false,
      xstableData: [],
      xssearchForm: {
        time: ""
      },
      xstotal: 0,
      SFlist: [],
      CSlist: [],
      imgIndex: 0,
      Userlist: [],
      isAdd: false,
      SSlist: [],
      v1:true,
      hy1:true,
      gs1:true,
      zy1:true,
      zj1:true,
      y1:'D',

      v2:true,
      hy2:true,
      gs2:true,
      zy2:true,
      zj2:true,
      y2:'D',

      v3:true,
      hy3:true,
      gs3:true,
      zy3:true,
      zj3:true,
      y3:'D',

      v4:true,
      hy4:true,
      gs4:true,
      zy4:true,
      zj4:true,
      y4:'D',

      vmsg:"匹配结果",
      importConfig:{
        remote: true,
        importMethod: this.importMethod ,
        types:['xlsx'],
        modes: ['insert']
      }
    };
  },
  created() {
    this.$store.commit("biaobaiqiangPage", 1);
    this.$store.commit("biaobaiqiangPageSize", 10);
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await this.$api.modellist({
        page: this.biaobaiqiangPage,
        pagesize: this.biaobaiqiangPageSize,
        hy1:this.hy1,
        hy2:this.hy2,
        hy3:this.hy3,
        hy4:this.hy4,
        gs1:this.gs1,
        gs2:this.gs2,
        gs3:this.gs3,
        gs4:this.gs4,
        zy1:this.zy1,
        zy2:this.zy2,
        zy3:this.zy3,
        zy4:this.zy4,
        zj1:this.zj1,
        zj2:this.zj2,
        zj3:this.zj3,
        zj4:this.zj4,
        y1:this.y1,
        y2:this.y2,
        y3:this.y3,
        y4:this.y4,
        v1:this.v1,
        v2:this.v2,
        v3:this.v3,
        v4:this.v4,

      });
      console.log(res);
      this.tableData = res.list ? res.list : [];
      this.total = res.total
      this.loading = false;
    },
    headerCellClassName ({ column}) {
      //console.log(columnIndex)
      if (column.property === 'hy1') {
        return 'cell-green'
      }
    },
    rowClassName ({rowIndex }) {
      //console.log(row);
      if ([2, 3, 5].includes(rowIndex)) {
        return 'cell-green'
      }
    },
    cellClassName({row,rowIndex, column, columnIndex}){
      console.log(rowIndex);
      console.log(column);
      console.log(columnIndex);
      console.log(row)
      return 'cell-green';
    },
    changeSF() {
      this.getCSData()
    },
    xssearchOnSubmit() {
      this.getxsData();
    },
    addEditGuanliyuan(row) {
      this.id = row.id;
      this.addForm.user_name = row.store_username;
      this.addForm.status = row.store_userstatus.toString();
      this.addDialogVisible = true;
    },
    seeXiaoshou(row) {
      this.id = row.id;
      this.xsaddDialogVisible = true;
    },
    xsaddHandleClose() {
      this.xsaddDialogVisible = false;
    },
    // 开关（显示/隐藏）
    async changeKG2(row) {
      console.log(row);
      const res = await this.$api.store_setflag({
        id: row.id,
        flag: row.myFlagship == true ? "1" : "0"
      });
      console.log(res);
      if (res.data.result == 1) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        this.getData();
      }
    },
    async changeKG(row) {
      console.log(row);
      const res = await this.$api.store_setstatus({
        id: row.id,
        status: row.myIs_status == true ? "1" : "0"
      });
      if (res.data.result == 1) {
        this.$message({
          message: res.data.msg,
          type: "success"
        });
        this.addDialogVisible = false;
        this.getData();
      }
    },
    toEdit(row) {
      console.log(row)
      this.id = row.id
      this.addForm = { ...row }
      this.$set(this.addForm,'url_type',row.url_types)
      this.$set(this.addForm,'category',row.category_id)
      this.$set(this.addForm,'url',row.url_address)
      // this.addForm.url_type = row.url_types;
      // this.addForm.category = row.category_id;
      // this.addForm.url = row.url_address;
      this.isAdd = false;
      // this.addForm.is_status = row.is_status.toString()
      this.addDialogVisible = true
    },
    async toDel(row) {
      const res = await this.$api.del_swiper({
        id: row.id
      })
      if (res.result == 1) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getData();
      }
    },
    tabsHandleClick(tab) {
      console.log(tab.index);
      this.$store.commit("tabShopIndex", (Number(tab.index) + 1).toString());
    },
    searchOnSubmit() {
      console.log(this.searchForm);
    },
    change1(){
        this.hy1=this.v1
        this.gs1=this.v1
        this.zy1=this.v1
        this.zj1=this.v1
      if(this.v1){
        this.y1="D"
      }else{
        this.y1=false;
      }
    },
    change2(){
      this.hy2=this.v2
      this.gs2=this.v2
      this.zy2=this.v2
      this.zj2=this.v2
      if(this.v2){
        this.y2="D"
      }else{
        this.y2=false;
      }
    },
    change3(){
      this.hy3=this.v3
      this.gs3=this.v3
      this.zy3=this.v3
      this.zj3=this.v3
      if(this.v3){
        this.y3="D"
      }else{
        this.y3=false;
      }
    },
    change4(){
      this.hy4=this.v4
      this.gs4=this.v4
      this.zy4=this.v4
      this.zj4=this.v4
      if(this.v4){
        this.y4="D"
      }else{
        this.y4=false;
      }
    },
    AddModel() {
      this.isAdd = true;
      for (const key in this.addForm) {
        if (key != 'details_pic') {
          this.$set(this.addForm, key, "");
        }
      }
      this.addDialogVisible = true;
    },
    importMethod({file,options }){
      console.log(file)
      console.log(options)
      // 处理表单
      const formBody = new FormData()
      formBody.append('file', file)
      formBody.append('scene', "import_model")
      // 上传文件
      return this.$api.import_excel(formBody).then(res => {
        console.log(res)
        if (res.data.result == 1) {
          this.getData()
          this.$message({
            type: 'success',
            message: '导入成功'
          });
        } else {
          this.$message({
            type: 'error',
            message: '导入失败'
          });
        }
      }).catch(() => {
        this.$message({ content: '导入失败，请检查数据是否正确！', status: 'error' })
      })
    },
    ImportModel(){
      this.$refs.myTable.importData()
    },
    async ModelCheck(){
      const res =await this.$api.modelcheck({
        hy1:this.hy1,
        hy2:this.hy2,
        hy3:this.hy3,
        hy4:this.hy4,
        gs1:this.gs1,
        gs2:this.gs2,
        gs3:this.gs3,
        gs4:this.gs4,
        zy1:this.zy1,
        zy2:this.zy2,
        zy3:this.zy3,
        zy4:this.zy4,
        zj1:this.zj1,
        zj2:this.zj2,
        zj3:this.zj3,
        zj4:this.zj4,
        y1:this.y1,
        y2:this.y2,
        y3:this.y3,
        y4:this.y4,
        v1:this.v1,
        v2:this.v2,
        v3:this.v3,
        v4:this.v4,

      });
      console.log(res);
      //alert(JSON.stringify(res.list))
      var result=res.list;
      this.vmsg=JSON.stringify(result);
      /*
      this.vmsg="1.模型过滤："+result.model_filter+"\n"+
          "2.模型合并：\n" +
          "hy1="+result.hy1+",gs1="+result.gs1+",zy1="+result.zy1+",zj1="+result.zj1+"->"+"hy1="+result.hy1_map+",gs1="+result.gs1_map+",zy1="+result.zy1_map+",zj1="+result.zj1_map+"\n"+
          "hy2="+result.hy2+",gs2="+result.gs2+",zy2="+result.zy2+",zj2="+result.zj2+"->"+"hy2="+result.hy2_map+",gs2="+result.gs2_map+",zy2="+result.zy2_map+",zj2="+result.zj2_map+"\n"+
          "hy3="+result.hy3+",gs3="+result.gs3+",zy3="+result.zy3+",zj3="+result.zj3+"->"+"hy3="+result.hy3_map+",gs3="+result.gs3_map+",zy3="+result.zy3_map+",zj3="+result.zj3_map+"\n"+
          "hy4="+result.hy4+",gs4="+result.gs4+",zy4="+result.zy4+",zj4="+result.zj4+"->"+"hy4="+result.hy4_map+",gs4="+result.gs4_map+",zy4="+result.zy4_map+",zj4="+result.zj4_map+"\n"
       */
      this.vmsg="1.模型过滤："+result.model_filter+"\n"+
          "2.模型合并：\n" +
          ""+result.hy1+","+result.gs1+","+result.zy1+","+result.zj1+"-->"+""+result.hy1_map+","+result.gs1_map+","+result.zy1_map+","+result.zj1_map+"\n"+
          ""+result.hy2+","+result.gs2+","+result.zy2+","+result.zj2+"-->"+""+result.hy2_map+","+result.gs2_map+","+result.zy2_map+","+result.zj2_map+"\n"+
          ""+result.hy3+","+result.gs3+","+result.zy3+","+result.zj3+"-->"+""+result.hy3_map+","+result.gs3_map+","+result.zy3_map+","+result.zj3_map+"\n"+
          ""+result.hy4+","+result.gs4+","+result.zy4+","+result.zj4+"-->"+""+result.hy4_map+","+result.gs4_map+","+result.zy4_map+","+result.zj4_map+"\n"
      //顺便模型调值
      if(result.model_filter==0){
        this.loading = true;
        const res = await this.$api.modellist({
          modelcheck:1,
          page: this.biaobaiqiangPage,
          pagesize: this.biaobaiqiangPageSize,
          hy1:result.hy1_map,
          hy2:result.hy2_map,
          hy3:result.hy3_map,
          hy4:result.hy4_map,
          gs1:result.gs1_map,
          gs2:result.gs2_map,
          gs3:result.gs3_map,
          gs4:result.gs4_map,
          zy1:result.zy1_map,
          zy2:result.zy2_map,
          zy3:result.zy3_map,
          zy4:result.zy4_map,
          zj1:result.zj1_map,
          zj2:result.zj2_map,
          zj3:result.zj3_map,
          zj4:result.zj4_map,
          y1:this.y1,
          y2:this.y2,
          y3:this.y3,
          y4:this.y4,
          v1:this.v1,
          v2:this.v2,
          v3:this.v3,
          v4:this.v4,})
        console.log(res);
        this.tableData = res.list ? res.list : [];
        this.total = res.total
        this.loading = false;
      }
    },

    SearchModel(){
      this.getData()
    },
    addHandleClose() {
      this.addDialogVisible = false;
    },
    tabEdit(row) {
      console.log(row);
      this.id = row.id;
      row.is_show = row.status == "0" ? "隐藏" : "显示";
      this.addForm = { ...row };
      this.addDialogVisible = true;
    },
    async tabDel(row) {
      console.log(row);
      const res = await this.$api.categoryDel(row.id);
      if (res) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        setTimeout(() => {
          this.getData();
        }, 500);
      } else {
        this.$message.error(res.msg);
      }
    },
    AddOnSubmit(formName) {
      console.log(this.addForm);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (this.isAdd) {
            // 新增
            const res = await this.$api.add_swiper({
              ...this.addForm,
            });
            if (res.result == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.addDialogVisible = false;
              this.getData();
            } else {
              this.$message({
                message: res.msg,
              });
            }
          } else {
            // 修改
            const res = await this.$api.edit_swiper({
              ...this.addForm,
              id:this.id
            });
            if (res.result == 1) {
              this.$message({
                message: res.msg,
                type: "success"
              });
              this.addDialogVisible = false;
              this.getData();
            }else {
              this.$message({
                message: res.msg,
              });
            }
          }

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除图片
    delImg(val, i = 0) {
      if (val == "details_pic") {
        if (this.addForm.details_pic[i].name != '') {
          this.addForm.details_pic.splice(i, 1)
        }
      } else if (val == "tb") {
        this.$set(this.addForm,'pic','')
      }
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$store.commit("biaobaiqiangPageSize", val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$store.commit("biaobaiqiangPage", val);
    },
    xshandleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$store.commit("xiaoshoubiaoPageSize", val);
    },
    xshandleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$store.commit("xiaoshoubiaoPage", val);
    },
    // 上传图片
    companyList(val, i = 0) {
      this.imgIndex = i;
      this.imgStatus = val;
      this.$refs.fileInputList.click();
    },
    //将文件转为blob类型
    readFileAsBuffer(file) {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64File = reader.result.replace(
              /^data:\w+\/\w+;base64,/,
              ""
          );
          resolve(new window.OSS.Buffer(base64File, "base64"));
        };
      });
    },
    async companyLogo(event) {
      const that = this;
      var file = event.target.files[0];
      var fileSize = file.size; //文件大小
      var filetType = file.type; //文件类型
      //创建文件读取对象
      // console.log(file);
      if (fileSize <= 10240 * 1024) {
        if (
            filetType == "image/png" ||
            filetType == "image/jpeg" ||
            filetType == "image/gif"
        ) {
          var file_re = await this.readFileAsBuffer(file);
          // console.log(this.imgFile);
          const res = await this.$api.aliyun_osssts()
          let client = new window.OSS.Wrapper({
            secure:true,
            region:res.region, //oss地址
            accessKeyId: res.accessKeyId, //ak
            accessKeySecret: res.accessKeySecret, //secret
            stsToken: res.stsToken,
            bucket:res.bucket, //oss名字
          });
          var imgtype = file.type.substr(6, 4);
          var store = `${new Date().getTime()}.${imgtype}`;
          console.log(store);
          client.put(store, file_re).then(() => {
            //这个结果就是url
            var a = client.signatureUrl(store);
            if (a.match('^http://')) {
              a = a.replace("http://", "https://")
            }
            this.oss_imgurl = a;
            console.log(a);
            if(this.imgStatus == 'tb'){
              this.$set(this.addForm,'pic',a)
            }
          });
          that.$refs.fileInputList.value = "";
        } else {
          this.$message.error("图片格式不正确");
        }
      } else {
        this.$message.error("图片大小不正确");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.index {}

.nav1 {
  margin: 0 -18px;
  background-color: #fff;
  padding: 20px 32px 0 40px;

  .tit1 {
    color: #17233d;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
  }
}

.myForm {
  ::v-deep  .el-form-item__label {
    font-size: 12px;
  }

  ::v-deep  .el-form-item {
    margin-right: 30px;
    margin-bottom: 0;
    vertical-align: middle;
  }
}

.tit1 {
  margin-top: 10px;
}
.cell-green {
  background-color: green;
  color:red;
}

.myTable {
  .xiala {
    padding: 10px 20px;

    .item {
      font-size: 12px;
      display: flex;
    }
  }



  margin-top: 10px;

  ::v-deep  .vxe-table--render-default .vxe-body--column {
    line-height: 14px;
    vertical-align: middle;
  }

  ::v-deep  .vxe-cell--label {
    font-size: 12px;
  }

  ::v-deep  .vxe-cell--title {
    font-size: 12px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.nav2 {
  margin: 18px 0;
  background: #fff;
  border-radius: 6px;
  padding: 24px;

  .myForm {
    ::v-deep  .el-form-item__label {
      font-size: 12px;
    }

    ::v-deep  .el-form-item {
      margin-right: 30px;
      margin-bottom: 0;
      vertical-align: middle;
    }
  }

  .tit1 {
    margin-top: 10px;
  }

  .myTable {
    margin-top: 10px;

    ::v-deep  .vxe-table--render-default .vxe-body--column {
      line-height: 14px;
      vertical-align: middle;
    }

    ::v-deep  .vxe-cell--label {
      font-size: 12px;
    }

    ::v-deep  .vxe-cell--title {
      font-size: 12px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.myAddForm {
  ::v-deep  .el-select {
    width: 100%;
  }

  ::v-deep  .el-form-item__label {
    font-size: 12px;
    width: 130px !important;
  }

  ::v-deep  .el-form-item__content {
    margin-left: 130px !important;
  }

  ::v-deep  .el-radio__label {
    font-size: 12px;
  }

  ::v-deep  .el-button {
    width: 100%;
  }

}

.fenye {
  margin-top: 16px;
}

.displayN {
  display: none;
}

.myImg {
  position: relative;
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-right: 12px;

  .closeBtn {
    position: absolute;
    top: -6px;
    right: -4px;
    width: 20px;
    height: 20px;

    .el-button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ::v-deep  .image-slot {
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fafafa;
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-icon-picture-outline {
      font-size: 20px;
    }
  }

  .myImg {
    position: relative;
    width: 60px;
    height: 60px;
    display: inline-block;
    margin-right: 12px;

    .closeBtn {
      position: absolute;
      top: -6px;
      right: -4px;
      width: 20px;
      height: 20px;

      .el-button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    ::v-deep  .image-slot {
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #fafafa;
      width: 58px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .el-icon-picture-outline {
        font-size: 20px;
      }
    }
  }
}
</style>
